
.center {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  max-width: 150px;
}

.title {
  padding-bottom: 15px;
  font-size: 30px;
}

form {
  width: 100%;
}

button[type="submit"] {
  width: 100%;
}
